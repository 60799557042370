<template>
  <div class="classifyAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="资产大类:" prop="assetName">
            <el-input type="text" v-model="inform.assetName" placeholder="请输入资产大类"></el-input>
          </el-form-item>
          <el-form-item label="资产小类:" prop="childrenAssetList">
            <div class="item flex-aic" v-for="(item,index) in inform.childrenAssetList">
              <div class="inputDiv">
                <el-input v-model="item.assetName" placeholder="资产小类名称"></el-input>
              </div>
              <el-button type="text" @click="delItem(index)" class="delBtn error ml10" icon="el-icon-remove-outline"></el-button>
            </div>
            <div class="addBtn" @click="addItem()">新增一行</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        assetName:'',//资产大类
        childrenAssetList:[],//资产小类
      },

      rules :{
        assetName: [
          { required: true, message: '请输入资产大类', trigger: 'blur' },
        ],
        childrenAssetList: [
          { required: true, message: '请输入资产小类', trigger: 'blur' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '新增资产分类'
        }else if(type == 'edit'){
          vm.dioTit = '编辑资产分类'
          vm.assetDetail(item.id)
        }
      })
    },
    //获取场景详情
    async assetDetail(id){
      const res = await apiUrl.assetDetail(id)
      if(res?.code==200){
        vm.inform = {
          id:res.data.id,
          assetName:res.data.assetName,//资产大类
          childrenAssetList:res.data.childrenAssetList,//资产小类
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //新增检查项
    addItem(){
      vm.inform.childrenAssetList.push({
        assetName:''
      })
    },
    //移除检查项
    delItem(index){
      vm.inform.childrenAssetList.splice(index,1)
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addAsset()
          }else if(vm.dioType=='edit'){
            vm.updateAsset()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        id:'',
        assetName:'',//资产大类
        childrenAssetList:[],//资产小类
      }
      vm.isDio = false
    },
    //新增
    async addAsset(){
      if(vm.checkNamesNotEmpty(vm.inform.childrenAssetList)==false) return vm.$message.error('请填写资产小类')
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'assetName':vm.inform.assetName,//名称
        'childrenAssetList':vm.inform.childrenAssetList,//执行设备
      }
      const res = await apiUrl.addAsset(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateAsset(){
      if(vm.checkNamesNotEmpty(vm.inform.childrenAssetList)==false) return vm.$message.error('请填写资产小类')
      let dataObj = {
        'id':vm.inform.id,
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'assetName':vm.inform.assetName,//名称
        'childrenAssetList':vm.inform.childrenAssetList,//执行设备
      }
      const res = await apiUrl.updateAsset(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //判断检查项是否存在空值
    checkNamesNotEmpty(arr) {  
      // 遍历数组  
      for (let i = 0; i < arr.length; i++) {  
        // 检查当前对象的name属性是否为空字符串  
        if (arr[i].assetName === "") {  
          // 如果发现有空字符串，则返回false  
          return false;  
        }  
      }  
      // 如果遍历完整个数组都没有发现空字符串，则返回true  
      return true;  
    }
  }
}
</script>
<style lang='scss'>
.classifyAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .item{
      .inputDiv{
        flex-grow: 1;
      }
      .delBtn{
        font-size: 20px;
        flex-shrink: 0;
      }
    }
    .addBtn{
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid #00c6ff;
      color: #00c6ff;
      margin-top: 10px;
      line-height: 26px;
      &:hover{
        box-shadow: 0px -5px 27px 0px rgba(7, 130, 255, 0.55) inset;
      }
    }
  }
}
</style>